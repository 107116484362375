import { ReactComponent as CaritecoSvg } from 'icons/svg/cariteco/logo_cariteco.svg';
type Props = {
  className?: string;
};
export default function CaritecoIcon(props: Props) {
  return (
    <span className={props.className}>
      <CaritecoSvg />
    </span>
  );
}
