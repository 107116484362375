import { TEXT_LINK_COLOR } from 'constants/common';

type Props = {
  className?: string;
  color?: string;
};

export default function AccountFilled(props: Props) {
  const { color = TEXT_LINK_COLOR } = props;
  return (
    <span className={props.className}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.57323 7.80612C8.28973 7.64812 8.23623 7.26912 8.45873 7.03262C9.15273 6.29162 9.50023 5.66662 9.50023 4.00012C9.50023 2.50012 8.50023 1.00012 6.49923 1.00012C4.50023 1.00012 3.50023 2.50012 3.50023 4.00012C3.50023 5.66662 3.84723 6.29162 4.54173 7.03262C4.76373 7.26912 4.71023 7.64812 4.42673 7.80612C3.75823 8.17812 2.66073 8.83912 2.00023 9.50012C1.16223 10.3376 1.02623 12.2281 1.00423 13.9956C0.997231 14.5491 1.44473 15.0001 1.99823 15.0001H11.0012C11.5547 15.0001 12.0027 14.5491 11.9957 13.9956C11.9737 12.2281 11.8377 10.3376 11.0002 9.50012C10.3392 8.83912 9.24173 8.17812 8.57323 7.80612Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 3V1H12V3H10V4H12V6H13V4H15V3H13Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
