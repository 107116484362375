import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  CouponDetailInfoResponse,
  fetchError,
  PriceWithCouponRequest,
  PriceWithCouponResponse,
  ReservationInfoData,
  ReservationStep1Response,
  ReservationStepError,
  AdjustedTime,
} from 'ReservationStep1-Types';
import {
  fetchReservationStep1InfoAsync,
  getReservationStep1Async,
  setOriginalPage,
  setStep1PriceWithCouponReqData,
  fetchCouponDetailInfoAsync,
  fetchPriceWithCouponAsync,
  setPriceWithCouponReqTemporaryData,
  setIsCouponOneClickLink,
  setTimeAdjustedData,
  setIsFetchAPIData,
  setIsStep2Back,
} from './actions';
import { priceWithCouponInitReducer, reStep1InitReducer } from 'constants/reservationStep';
import { STATIONS } from 'constants/url';

export const isGettingStep1 = createReducer<boolean>(false)
  .handleAction([getReservationStep1Async.request], () => true)
  .handleAction([getReservationStep1Async.success, getReservationStep1Async.failure], () => false);

export const bookingInfo = createReducer<ReservationInfoData>(
  {} as ReservationInfoData,
).handleAction(getReservationStep1Async.success, (_state, action) => action.payload);

export const isStep2Back = createReducer<boolean>(false).handleAction(
  [setIsStep2Back],
  (_, action) => action.payload,
);

export const isFetchAPIData = createReducer<boolean>(true).handleAction(
  [setIsFetchAPIData],
  (_, action) => action.payload,
);

export const reservationStep1Info = createReducer<ReservationStep1Response>(
  reStep1InitReducer,
).handleAction(fetchReservationStep1InfoAsync.success, (_state, action) => action.payload);

export const isFetchingReservationStep1Info = createReducer<boolean>(false)
  .handleAction(
    [fetchReservationStep1InfoAsync.success, fetchReservationStep1InfoAsync.failure],
    () => false,
  )
  .handleAction(fetchReservationStep1InfoAsync.request, () => true);

export const errors = createReducer<fetchError>({} as fetchError).handleAction(
  fetchReservationStep1InfoAsync.failure,
  (_state, action) => action.payload,
);

export const getOriginalPage = createReducer<string>(STATIONS).handleAction(
  [setOriginalPage],
  (_, action) => action.payload,
);

export const getStep1PriceWithCouponReqData = createReducer<PriceWithCouponRequest>(
  {} as PriceWithCouponRequest,
).handleAction([setStep1PriceWithCouponReqData], (_, action) => action.payload);

export const getPriceWithCouponReqTemporaryData = createReducer<PriceWithCouponRequest>(
  {} as PriceWithCouponRequest,
).handleAction([setPriceWithCouponReqTemporaryData], (_, action) => action.payload);

export const getIsCouponOneClickLink = createReducer<boolean>(false).handleAction(
  [setIsCouponOneClickLink],
  (_, action) => action.payload,
);

export const getTimeAdjustedData = createReducer<AdjustedTime>({} as AdjustedTime).handleAction(
  [setTimeAdjustedData],
  (_, action) => action.payload,
);

export const isFetchingCouponDetailInfo = createReducer<boolean>(false)
  .handleAction([fetchCouponDetailInfoAsync.request], () => true)
  .handleAction(
    [fetchCouponDetailInfoAsync.success, fetchCouponDetailInfoAsync.failure],
    () => false,
  );
export const isFetchingCouponDetailInfoSuccess = createReducer<CouponDetailInfoResponse>(
  {} as CouponDetailInfoResponse,
).handleAction(fetchCouponDetailInfoAsync.success, (_state, action) => action.payload);
export const isFetchingCouponDetailInfoFailure = createReducer<ReservationStepError>(
  {} as ReservationStepError,
).handleAction(fetchCouponDetailInfoAsync.failure, (_state, action) => action.payload);

export const isFetchingPriceWithCoupon = createReducer<boolean>(false)
  .handleAction([fetchPriceWithCouponAsync.request], () => true)
  .handleAction(
    [fetchPriceWithCouponAsync.success, fetchPriceWithCouponAsync.failure],
    () => false,
  );

export const isFetchingPriceWithCouponSuccess = createReducer<PriceWithCouponResponse>(
  priceWithCouponInitReducer,
).handleAction(fetchPriceWithCouponAsync.success, (_state, action) => action.payload);

export const isFetchingPriceWithCouponFailure = createReducer<ReservationStepError>(
  {} as ReservationStepError,
).handleAction(fetchPriceWithCouponAsync.failure, (_state, action) => action.payload);

const reservationStepReducer = combineReducers({
  isGettingStep1,
  bookingInfo,
  isStep2Back,
  isFetchAPIData,
  reservationStep1Info,
  errors,
  isFetchingReservationStep1Info,
  getOriginalPage,
  getStep1PriceWithCouponReqData,
  getIsCouponOneClickLink,
  getPriceWithCouponReqTemporaryData,
  getTimeAdjustedData,
  isFetchingCouponDetailInfo,
  isFetchingCouponDetailInfoSuccess,
  isFetchingCouponDetailInfoFailure,
  isFetchingPriceWithCoupon,
  isFetchingPriceWithCouponSuccess,
  isFetchingPriceWithCouponFailure,
});

export default reservationStepReducer;
export type ReservationStepState = ReturnType<typeof reservationStepReducer>;
