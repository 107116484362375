type Props = {
  className?: string;
};

export default function ChevronRight(props: Props) {
  return (
    <span className={props.className}>
      <svg
        width="17"
        height="24"
        viewBox="0 0 17 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.457 6.5L4.75 7.207L10.543 13L4.75 18.793L5.457 19.5L11.957 13L5.457 6.5Z"
          fill="black"
        />
      </svg>
    </span>
  );
}
