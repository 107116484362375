import { ReactComponent as MitsuiSvg } from 'icons/svg/mitsui/logo_mitsui.svg';
type Props = {
  className?: string;
};
export default function MitsuiIcon(props: Props) {
  return (
    <span className={props.className}>
      <MitsuiSvg />
    </span>
  );
}
