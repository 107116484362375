type Props = {
  className?: string;
  isDisable?: boolean;
};
export default function ReceiptIcon(props: Props) {
  const { className, isDisable } = props;
  return (
    <span className={className}>
      <svg
        width="17"
        height="24"
        viewBox="0 0 17 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 19H13V11H14V19C14 19.552 13.552 20 13 20H4C3.448 20 3 19.552 3 19V7C3 6.448 3.448 6 4 6H9V7H4V19ZM11 10H14L10 6V9C10 9.552 10.448 10 11 10ZM6.5 12H5.5V11H6.5V12ZM11.5 12H7.5V11H11.5V12ZM7.5 14.5H11.5V13.5H7.5V14.5ZM6.5 14.5H5.5V13.5H6.5V14.5ZM7.5 17H11.5V16H7.5V17ZM6.5 17H5.5V16H6.5V17Z"
          fill={isDisable === undefined ? '#FFFFFF' : isDisable ? '#606060' : '#333333'}
        />
      </svg>
    </span>
  );
}
