import { ReactComponent as OrixSvg } from 'icons/svg/orix/logo_orix.svg';
type Props = {
  className?: string;
};
export default function OrixIcon(props: Props) {
  return (
    <span className={props.className}>
      <OrixSvg />
    </span>
  );
}
