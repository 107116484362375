import { createAsyncAction, AppThunk } from 'typesafe-actions';
import {
  ReCancelReq,
  ErrorResponse,
  ReservationLockingResponse,
  ReservationLockingRequest,
} from 'ReservationInfo-Types';
import { fetchCancelReservationData, fetchLockingData } from './api';

export const fetchCancelReservationAsync = createAsyncAction(
  'FETCH_CANCEL_RE_REQUEST',
  'FETCH_CANCEL_RE_SUCCESS',
  'FETCH_CANCEL_RE_FAILURE',
)<undefined, ErrorResponse, ErrorResponse>();

export const fetchCancelRe = (req: ReCancelReq): AppThunk => async dispatch => {
  dispatch(fetchCancelReservationAsync.request());
  try {
    const response = await fetchCancelReservationData(req);
    dispatch(fetchCancelReservationAsync.success(response));
    dispatch(fetchCancelReservationAsync.failure({} as ErrorResponse));
  } catch (error) {
    dispatch(fetchCancelReservationAsync.failure(error as ErrorResponse));
  }
};

export const fetchLockingAsync = createAsyncAction(
  'FETCH_LOCKING_REQUEST',
  'FETCH_LOCKING_SUCCESS',
  'FETCH_LOCKING_FAILURE',
)<undefined, ReservationLockingResponse, ErrorResponse>();

export const fetchLocking = (req: ReservationLockingRequest): AppThunk => async dispatch => {
  dispatch(fetchLockingAsync.request());
  try {
    const response = await fetchLockingData(req);
    dispatch(fetchLockingAsync.success(response));
    dispatch(fetchLockingAsync.failure({} as ErrorResponse));
  } catch (error) {
    dispatch(fetchLockingAsync.failure(error as ErrorResponse));
  }
};
