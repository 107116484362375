import { createAsyncAction, AppThunk, createAction } from 'typesafe-actions';
import {
  CarAvailabilityRequest,
  CarAvailabilityResponse,
  CheckCouponChangeRequest,
  CheckCouponChangeResponse,
  ReservationChangeStep1InitialRequest,
  ReservationChangeStep1InitialResponse,
  ReservationChangeStepError,
} from 'ReservationChangeStep1-Types';
import {
  fetchReservationChangeStep1InitialData,
  fetchCarAvailabilityData,
  checkCouponChangeData,
} from './api';
import { fetchError } from 'ReservationStep1-Types';

export const fetchReservationChangeStep1InitialAsync = createAsyncAction(
  'FETCH_RESERVATION_CHANGE_STEP1_INITIAL_REQUEST',
  'FETCH_RESERVATION_CHANGE_STEP1_INITIAL_SUCCESS',
  'FETCH_RESERVATION_CHANGE_STEP1_INITIAL_FAILURE',
)<undefined, ReservationChangeStep1InitialResponse, ReservationChangeStepError>();

export const fetchReservationChangeStep1Initial = (
  req: ReservationChangeStep1InitialRequest,
): AppThunk => async dispatch => {
  dispatch(fetchReservationChangeStep1InitialAsync.request());
  try {
    const response = await fetchReservationChangeStep1InitialData(req);
    dispatch(fetchReservationChangeStep1InitialAsync.success(response));
    dispatch(fetchReservationChangeStep1InitialAsync.failure({} as ReservationChangeStepError));
  } catch (error) {
    dispatch(fetchReservationChangeStep1InitialAsync.failure(error as ReservationChangeStepError));
  }
};

export const getReservationChangeStep1InitialRequest = createAction(
  'GET_RESERVATION_CHANGE_STEP1_INITIAL_REQUEST',
)<ReservationChangeStep1InitialRequest>();

export const fetchCarAvailabilityAsync = createAsyncAction(
  'FETCH_CAR_AVAILABILITY_REQUEST',
  'FETCH_CAR_AVAILABILITY_SUCCESS',
  'FETCH_CAR_AVAILABILITY_FAILURE',
)<undefined, CarAvailabilityResponse, ReservationChangeStepError>();

export const fetchCarAvailability = (req: CarAvailabilityRequest): AppThunk => async dispatch => {
  dispatch(fetchCarAvailabilityAsync.request());
  try {
    const response = await fetchCarAvailabilityData(req);
    dispatch(fetchCarAvailabilityAsync.success(response));
    dispatch(fetchCarAvailabilityAsync.failure({} as ReservationChangeStepError));
  } catch (error) {
    dispatch(fetchCarAvailabilityAsync.failure(error as ReservationChangeStepError));
  }
};

export const checkCouponChangeAsync = createAsyncAction(
  'CHECK_COUPON_CHANGE_REQUEST',
  'CHECK_COUPON_CHANGE_SUCCESS',
  'CHECK_COUPON_CHANGE_FAILURE',
)<undefined, CheckCouponChangeResponse, ReservationChangeStepError>();

export const checkCouponChange = (req: CheckCouponChangeRequest): AppThunk => async dispatch => {
  dispatch(checkCouponChangeAsync.request());
  dispatch(checkCouponChangeAsync.failure({} as ReservationChangeStepError));
  try {
    const response = await checkCouponChangeData(req);
    dispatch(checkCouponChangeAsync.success(response));
  } catch (error) {
    dispatch(checkCouponChangeAsync.failure(error as fetchError));
  }
};

export const setUrlPage = createAction('URL_PAGE')<string>();
export const setReservationId = createAction('RESERVATION_ID')<
  ReservationChangeStep1InitialRequest
>();
export const setErrorBackFlag = createAction('ERROR_BACK_FLAG')<boolean>();
