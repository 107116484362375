import { HttpMethod, RequestWithSignal } from 'utils/fetch';
import {
  StationSearchCondition,
  StationInfoResponse,
  FetchCarSearchCondition,
  FetchCarSearchResponse,
} from 'Station-Info-Types';

export const getStationInfo = (req: StationSearchCondition): Promise<StationInfoResponse> =>
  RequestWithSignal(`/station/get`, {
    method: HttpMethod.get,
    body: req,
    isSearch: true,
  }).then((res: StationInfoResponse) => {
    return res;
  });

export const fetchCarInfoOnStation = (
  req: FetchCarSearchCondition,
): Promise<FetchCarSearchResponse> => {
  const BodyReq = {
    departureDateTime: req.departureDateTime,
    returnDateTime: req.returnDateTime,
    partnerId: req.partnerId,
    category: req?.category || undefined,
  };
  return RequestWithSignal(`/station/${req.stationId}/cars`, {
    method: HttpMethod.get,
    body: BodyReq,
    isSearch: true,
  }).then((res: FetchCarSearchResponse) => {
    return res;
  });
};
