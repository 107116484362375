import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { store, persistor } from 'store';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Wrapper } from '@googlemaps/react-wrapper';

// Creates one instance of QueryClient to use for Station Search ONLY.
// Please continue to use Redux/Redux Thunk for global state management
// in all other pages and functions.
//
// This is a temporary solution to avoid breaking changes to the existing
// codebase. The data will be synced with redux but
// The QueryClientProvider and singletonClient will be provide:
// the following required improves:
//    - faster code execution for search results,
//    - more reliable error handling for search results,
//    - having more advanced caching and fetching strategies,
//    - use mutations to update search results
//       - use query invalidation to update search results
//       - use query refetching to update search results
const singletonClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

render(
  <ReduxProvider store={store}>
    <QueryClientProvider client={singletonClient}>
      <PersistGate loading={<p>Loading...</p>} persistor={persistor}>
        <BrowserRouter>
          <ScrollToTop />
          <Wrapper version="weekly" apiKey="AIzaSyDWWEupfNZ4xLfmiNe3XP3UCYQ5W-qAcsk">
            <App />
          </Wrapper>
        </BrowserRouter>
      </PersistGate>{' '}
      {process.env.NODE_ENV === 'development' ? (
        <ReactQueryDevtools initialIsOpen={false} />
      ) : (
        undefined
      )}
    </QueryClientProvider>
  </ReduxProvider>,
  document.querySelector('#root'),
);

// Devtools only shows in development mode it can be used for easier debugging
// while working with search API results in local.
// To remove if it is not needed just make line 31 - 41 a comment and line 10 import statement
