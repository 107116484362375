import { combineReducers } from 'redux';
import {
  CarAvailabilityResponse,
  CheckCouponChangeResponse,
  ReservationChangeStep1InitialRequest,
  ReservationChangeStep1InitialResponse,
  ReservationChangeStepError,
} from 'ReservationChangeStep1-Types';
import { fetchError } from 'ReservationStep1-Types';
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';

const initialDataFetching = createReducer<ReservationChangeStep1InitialResponse>(
  {} as ReservationChangeStep1InitialResponse,
).handleAction(
  actions.fetchReservationChangeStep1InitialAsync.success,
  (state, action) => action.payload,
);

const isFetchingReChangeStep1 = createReducer<boolean>(false)
  .handleAction([actions.fetchReservationChangeStep1InitialAsync.request], () => true)
  .handleAction(
    [
      actions.fetchReservationChangeStep1InitialAsync.success,
      actions.fetchReservationChangeStep1InitialAsync.failure,
    ],
    () => false,
  );

const fetchingReChangeStep1Failure = createReducer<ReservationChangeStepError>(
  {} as ReservationChangeStepError,
).handleAction(
  actions.fetchReservationChangeStep1InitialAsync.failure,
  (_state, action) => action.payload,
);
const initialRequest = createReducer<ReservationChangeStep1InitialRequest>(
  {} as ReservationChangeStep1InitialRequest,
).handleAction([actions.getReservationChangeStep1InitialRequest], (state, action) => {
  return (state = action.payload);
});

const carAvailabilityDataFetching = createReducer<CarAvailabilityResponse>(
  {} as CarAvailabilityResponse,
).handleAction(actions.fetchCarAvailabilityAsync.success, (state, action) => action.payload);

const carAvailabilityDataError = createReducer<ReservationChangeStepError>(
  {} as ReservationChangeStepError,
).handleAction(actions.fetchCarAvailabilityAsync.failure, (_state, action) => action.payload);

const getUrlPage = createReducer<string>('').handleAction(
  [actions.setUrlPage],
  (_state, action) => action.payload,
);

const getReservationId = createReducer<string>('').handleAction(
  [actions.setReservationId],
  (_state, action) => action.payload,
);

const getErrorBackFlag = createReducer<boolean>(false).handleAction(
  [actions.setErrorBackFlag],
  (_state, action) => action.payload,
);

const checkCouponChangeResult = createReducer<CheckCouponChangeResponse>(
  {} as CheckCouponChangeResponse,
).handleAction(actions.checkCouponChangeAsync.success, (state, action) => action.payload);

const checkCouponChangeError = createReducer<fetchError>({} as fetchError).handleAction(
  actions.checkCouponChangeAsync.failure,
  (_state, action) => action.payload,
);

const reservationChangeStep1Reducer = combineReducers({
  isFetchingReChangeStep1,
  initialDataFetching,
  fetchingReChangeStep1Failure,
  initialRequest,
  carAvailabilityDataFetching,
  carAvailabilityDataError,
  getUrlPage,
  getReservationId,
  getErrorBackFlag,
  checkCouponChangeResult,
  checkCouponChangeError,
});

export default reservationChangeStep1Reducer;
