import Request, { HttpMethod, RequestWithSignal } from 'utils/fetch';
import {
  CouponDetailInfoRequest,
  CouponDetailInfoResponse,
  PriceWithCouponRequest,
  PriceWithCouponResponse,
  ReservationInfoData,
  ReservationStep1Request,
  ReservationStep1Response,
} from 'ReservationStep1-Types';

export const getReservationStep1 = (dataReservationStep1: ReservationInfoData) => {
  return dataReservationStep1;
};
export const getReservationStep1Info = (
  req: ReservationStep1Request,
): Promise<ReservationStep1Response> =>
  Request(`/reservation/step1`, {
    method: HttpMethod.get,
    body: req,
  }).then((res: ReservationStep1Response) => {
    return res;
  });
export const getCouponDetailInfo = (
  req: CouponDetailInfoRequest,
): Promise<CouponDetailInfoResponse> =>
  Request(`/reservation/couponDetailInfo`, {
    method: HttpMethod.get,
    body: req,
  }).then((res: CouponDetailInfoResponse) => {
    return res;
  });

export const fetchPriceWithCouponPriceData = (
  req: PriceWithCouponRequest,
): Promise<PriceWithCouponResponse> =>
  RequestWithSignal(`/reservation/priceWithCoupon`, {
    method: HttpMethod.post,
    body: req,
  }).then(response => {
    return response;
  });
