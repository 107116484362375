type Props = {
  className?: string;
  color?: string;
};

export default function Car(props: Props) {
  const { className = '', color = 'black' } = props;

  return (
    <span className={className}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.25 17.25H14.75V15H10.25V17.25ZM6.125 15C5.504 15 5 14.496 5 13.875C5 13.254 5.504 12.75 6.125 12.75C6.746 12.75 7.25 13.254 7.25 13.875C7.25 14.496 6.746 15 6.125 15ZM6.25175 3.75H18.7482L19.703 9H5.297L6.25175 3.75ZM20 13.875C20 14.496 19.496 15 18.875 15C18.254 15 17.75 14.496 17.75 13.875C17.75 13.254 18.254 12.75 18.875 12.75C19.496 12.75 20 13.254 20 13.875ZM22.748 10.872L21.9455 9.66825C21.7738 9.41175 21.527 9.228 21.2488 9.117L20.2243 3.48225C20.0945 2.76825 19.4727 2.25 18.7482 2.25H6.25175C5.52725 2.25 4.9055 2.76825 4.77575 3.48225L3.75125 9.117C3.473 9.228 3.22625 9.41175 3.0545 9.66825L2.252 10.872C2.08775 11.118 2 11.4082 2 11.7037V17.25C2 18.078 2.672 18.75 3.5 18.75V20.25C3.5 21.078 4.172 21.75 5 21.75H5.75C6.578 21.75 7.25 21.078 7.25 20.25V18.75H17.75V20.25C17.75 21.078 18.422 21.75 19.25 21.75H20C20.828 21.75 21.5 21.078 21.5 20.25V18.75C22.328 18.75 23 18.078 23 17.25V11.7037C23 11.4082 22.9123 11.118 22.748 10.872Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
