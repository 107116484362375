type Props = {
  className?: string;
  color?: string;
};

export default function CompleteMark(props: Props) {
  const { className, color = '#047205' } = props;

  return (
    <span className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 41 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31 13.5605L29.9395 12.5L16.75 25.6887L11.0605 20L10 21.0605L16.75 27.8105L31 13.5605Z"
          fill={color}
        />
        <rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke={color} />
      </svg>
    </span>
  );
}
