type Props = {
  className?: string;
  color?: string;
};
export default function LeftSlide(props: Props) {
  const { className, color = 'black' } = props;
  return (
    <span className={className}>
      <svg
        width="12"
        height="21"
        viewBox="0 0 12 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 20.25L11.5605 19.1895L2.871 10.5L11.5605 1.8105L10.5 0.75L0.75 10.5L10.5 20.25Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
