import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  StationInfoResponse,
  SingleStationInfo,
  StationSearchParams,
  SearchInfo,
} from 'Station-Info-Types';
import * as actions from './actions';
import { fetchError } from 'ReservationStep1-Types';

const stationInfo = createReducer<StationInfoResponse>({} as StationInfoResponse).handleAction(
  actions.fetchStationInfoAsync.success,
  (state, action) => (state = action.payload),
);

const singleStationInfo = createReducer<SingleStationInfo>({} as SingleStationInfo).handleAction(
  [actions.updateSingleStationInfo],
  (state, action) => {
    return (state = action.payload);
  },
);

const isDialogOpen = createReducer<boolean>(false).handleAction(
  actions.setDialogOpenStatus,
  (state, action) => {
    return (state = action.payload);
  },
);

const stationSearchParams = createReducer<StationSearchParams>(
  {} as StationSearchParams,
).handleAction([actions.updateStationSearchParams], (state, action) => {
  return (state = action.payload);
});

const searchInfo = createReducer<SearchInfo>({} as SearchInfo).handleAction(
  [actions.updateSearchInfo],
  (state, action) => {
    return (state = action.payload);
  },
);

const isFetching = createReducer(false)
  .handleAction(
    [actions.fetchStationInfoAsync.success, actions.fetchStationInfoAsync.failure],
    () => false,
  )
  .handleAction(actions.fetchStationInfoAsync.request, () => true);

const error = createReducer<fetchError>({} as fetchError).handleAction(
  actions.fetchStationInfoAsync.failure,
  (_state, action) => action.payload,
);

const stationSearchReducer = combineReducers({
  stationInfo,
  singleStationInfo,
  isDialogOpen,
  stationSearchParams,
  searchInfo,
  isFetching,
  error,
});

export default stationSearchReducer;
