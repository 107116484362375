type Props = {
  className?: string;
  color?: string;
};

export default function ChangeIcon(props: Props) {
  const { color = 'white' } = props;
  return (
    <span className={props.className}>
      <svg
        width="17"
        height="24"
        viewBox="0 0 17 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2068 8.28513C14.5978 8.67613 14.5978 9.31012 14.2068 9.70112L13.4858 10.4221L12.7783 9.71513L13.4993 8.99412L11.5058 7.00063L10.7848 7.72162L10.0778 7.01462L10.7988 6.29362C11.1898 5.90213 11.8238 5.90213 12.2148 6.29362L14.2068 8.28513ZM4.31055 14.4629L6.03755 16.1894L3.81705 16.6829L4.31055 14.4629ZM6.87805 15.6159L4.88405 13.6219L9.37005 9.13586L11.3641 11.1294L6.87805 15.6159ZM12.7786 11.1294L12.0711 10.4224L9.37305 7.72336L9.37055 7.72186L3.47355 13.6184L2.50005 17.9999L6.88155 17.0264L12.7786 11.1294ZM14.5 20H2.5V19H14.5V20Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
