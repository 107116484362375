import Request, { HttpMethod } from 'utils/fetch';
import {
  ErrorResponse,
  ReservationLockingRequest,
  ReservationLockingResponse,
  ReCancelReq,
} from 'ReservationInfo-Types';

export const fetchCancelReservationData = (req: ReCancelReq): Promise<ErrorResponse> =>
  Request(`/reservation/cancel/${req.reservationId}`, {
    method: HttpMethod.put,
    body: req,
  }).then(response => {
    return response;
  });
export const fetchLockingData = (
  req: ReservationLockingRequest,
): Promise<ReservationLockingResponse> =>
  Request(`/reservation/locking`, {
    method: HttpMethod.post,
    body: req,
  })
    .then(response => {
      return response;
    })
    // TODO: Temporary solution to resolve the failed promise,
    // add a logging service in future improvement
    .catch(error => {
      console.log(error);
      throw error;
    });
