// TOP
export const FREE_MEMBER_LOGIN_IN = 'https://carshare.rakuten.co.jp/content/guide/registration/';
// Header
export const PRICE = 'https://carshare.rakuten.co.jp/content/price/';
export const USE_GUIDE = 'https://carshare.rakuten.co.jp/content/guide/';
export const HELP = 'https://carshare.faq.rakuten.net/s/';
// Footer
export const RAKUTEN_GROUP_SERVICE = 'https://www.rakuten.co.jp/sitemap/sp/';
export const SITE_MAP = 'https://carshare.rakuten.co.jp/content/sitemap/';
export const CORPORATE_INFORMATION = 'https://corp.rakuten.co.jp/';
export const PRIVACY_POLICY = 'https://privacy.rakuten.co.jp/';
export const SPECIFIC_QUOTIENT_TRANSACTION = 'https://carshare.rakuten.co.jp/content/info/law/';
export const TERMS_AND_CONDITIONS = 'https://carshare.rakuten.co.jp/content/agreement/';
export const APP_LIST = 'https://www.rakuten.co.jp/sitemap/sp/app.html';
export const QA_LIST = 'https://www.rakuten.co.jp/sitemap/sp/inquiry.html';
export const SUSTAINABILITY = 'https://corp.rakuten.co.jp/sustainability/';
// TERMS
export const ORIX_TERMS_DOWNLOAD = 'https://carshare.rakuten.co.jp/content/info/orix/trade.pdf';
export const CARITECO_TERMS_DOWNLOAD =
  'https://carshare.rakuten.co.jp/content/info/cariteco/trade.pdf';
export const MITSUI_TERMS_DOWNLOAD = 'https://carshare.rakuten.co.jp/content/info/mitsui/trade.pdf';
export const ORIX_TERMS_PRIVACY_POLICY =
  'https://carshare.rakuten.co.jp/content/info/orix/privacy/';
export const CARITECO_TERMS_PRIVACY_POLICY =
  'https://carshare.rakuten.co.jp/content/info/cariteco/privacy/';
export const MITSUI_TERMS_PRIVACY_POLICY =
  'https://carshare.rakuten.co.jp/content/info/mitsui/privacy/';
// IMPORTANT_MATTERS
export const IMPORTANT_MATTERS_USE_GUIDE =
  'https://carshare.rakuten.co.jp/content/agreement/terms/';
export const IMPORTANT_MATTERS_PRIVACY =
  'https://carshare.rakuten.co.jp/content/agreement/privacy/';
// Membership Step1
export const MEMBER_INFO_ADDRESS_CHANGE = 'https://member.id.rakuten.co.jp/rms/nid/menufwd';
// Membership Step3
export const DETAIL = 'https://carshare.faq.rakuten.net/s/article/000031209';
// Reservation List
export const CANCEL_POLICY = 'https://carshare.faq.rakuten.net/s/article/000031219';
export const CANCEL_FAIL_HELP = 'https://carshare.faq.rakuten.net/s/article/000031218';
// Reservation Info
export const RECEIPT_QA = 'https://carshare.faq.rakuten.net/s/article/000031225';
// Reservation Step1
export const MEMBERSHIP_REGISTRATION_GUIDE =
  'https://carshare.rakuten.co.jp/content/guide/registration';
export const ORIX_PRICE_LIST_INFO =
  'https://carshare.rakuten.co.jp/content/price/step/orix/index.html';
export const CARITECO_PRICE_LIST_INFO =
  'https://carshare.rakuten.co.jp/content/price/step/cariteco/index.html';
export const MITSUI_PRICE_LIST_INFO =
  'https://carshare.rakuten.co.jp/content/price/step/mitsui/index.html';
// Reservation Step2
export const GRANT_POINT_DETAIL = 'https://carshare.faq.rakuten.net/s/article/000031226';
export const ORIX_GRANT_POINT = 'https://carshare.faq.rakuten.net/s/article/000031227';
export const ORIX_INFO = 'https://carshare.rakuten.co.jp/content/info/orix/';
export const CARITECO_INFO = 'https://carshare.rakuten.co.jp/content/info/cariteco/';
export const MITSUI_INFO = 'https://carshare.rakuten.co.jp/content/info/mitsui/';
export const COMPENSATION_LINK = 'https://carshare.rakuten.co.jp/content/guide/compensation/';
export const COUPON_HELP_LINK = 'https://carshare.rakuten.co.jp/content/guide/coupon/';
// MEMBER_STATUS
export const MEMBER_STATUS_USE_STOP_HELP = 'https://carshare.faq.rakuten.net/s/article/000031220';
export const MEMBER_STATUS_CHECKING_HELP = 'https://carshare.faq.rakuten.net/s/article/000031209';

// STATIC URL
const DOMAIN = 'https://carshare.rakuten.co.jp';
export const EMERGENCY_URL = `${DOMAIN}/content/top/emergency.html`;
export const BANNER_NON_LOGIN_URL = `${DOMAIN}/content/top/banner1.html`;
export const BANNER_NON_CAR_SHARE_MEMBER_URL = `${DOMAIN}/content/top/banner2.html`;
export const BANNER_CAR_SHARE_MEMBER_URL = `${DOMAIN}/content/top/banner3.html`;
export const CAMPAIGN_NON_LOGIN_URL = `${DOMAIN}/content/top/campaign1.html`;
export const CAMPAIGN_NON_CAR_SHARE_MEMBER_URL = `${DOMAIN}/content/top/campaign2.html`;
export const CAMPAIGN_CAR_SHARE_MEMBER_URL = `${DOMAIN}/content/top/campaign3.html`;
export const LINK_URL = `${DOMAIN}/content/top/link.html`;
export const INFORMATION_URL = `${DOMAIN}/content/top/information.html`;
export const PR_URL = `${DOMAIN}/content/top/pr.html`;
export const USER_GUIDE_NON_LOGIN_URL = `${DOMAIN}/content/top/userguide1.html`;
export const USER_GUIDE_NON_CAR_SHARE_MEMBER_URL = `${DOMAIN}/content/top/userguide2.html`;
export const USER_GUIDE_CAR_SHARE_MEMBER_URL = `${DOMAIN}/content/top/userguide3.html`;

export const RESERVATION1_USE = `${DOMAIN}/content/top/reservation1_use.html`;
export const RESERVATION1_CAMPAIGN_URL = `${DOMAIN}/content/top/reservation1_campaign.html`;
export const RESERVATION1_CHANGE_CAMPAIGN_URL = `${DOMAIN}/content/top/reservation1_change_campaign.html`;
export const RESERVATION1_CHANGE_USE = `${DOMAIN}/content/top/reservation1_change_use.html`;
export const RESERVATION_COMPLETE_USE = `${DOMAIN}/content/top/reservationcomplete_use.html`;
export const RESERVATION_COMPLETE_CAMPAIGN = `${DOMAIN}/content/top/reservationcomplete_campaign.html`;
export const RESERVATION_COMPLETE_CHANGE_USE = `${DOMAIN}/content/top/reservationcomplete_change_use.html`;
export const RESERVATION_COMPLETE_CHANGE_CAMPAIGN = `${DOMAIN}/content/top/reservationcomplete_change_campaign.html`;
export const MEMBER_COMPLETE_CAMPAIGN = `${DOMAIN}/content/top/membercomplete_campaign.html`;
export const MEMBER_COMPLETE_CHANGE_CAMPAIGN = `${DOMAIN}/content/top/membercomplete_change_campaign.html`;

export const TOP_PAGE_FOOTER_URL = `${DOMAIN}/content/common/footer/common_footer_d1.html`;
export const NON_TOP_PAGE_FOOTER_URL = `${DOMAIN}/content/common/footer/common_footer_d2.html`;

//busy
export const BUSY = 'https://carshare.rakuten.co.jp/content/error/busy.html';
//coupon
export const COUPON_USAGE_LINK = 'https://carshare.rakuten.co.jp/content/guide/coupon/';
export const ACQUIRE_COUPON_URL = 'https://coupon.rakuten.co.jp/acquireCoupon/json';
export const COUPON_URL = 'http://coupon.rakuten.co.jp';

//cancelHelp
export const CANCEL_HELP = 'https://carshare.rakuten.co.jp/content/guide/reservation/';

export const ORIX_IDM_GUIDE = 'https://carshare.rakuten.co.jp/content/guide/use/';
export const CARITECO_IDM_GUIDE = 'https://carshare.rakuten.co.jp/content/guide/use/cariteco/';

//mitsui image url
export const MITSUI_CAR_IMAGE_URL = `${DOMAIN}/content/img/mitsui/car/`;
export const MITSUI_STATION_IMAGE_URL = `${DOMAIN}/content/img/mitsui/station/`;
