import { MouseEventHandler } from 'react';

type Props = {
  className?: string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
};

export default function CurrentPosition(props: Props) {
  const { className, ...rest } = props;
  return (
    <span className={className} {...rest}>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="22" cy="22" r="22" fill="#0178D4" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.75 19.75C19.75 18.5072 20.7572 17.5 22 17.5C23.2428 17.5 24.25 18.5072 24.25 19.75C24.25 20.9928 23.2428 22 22 22C20.7572 22 19.75 20.9928 19.75 19.75ZM29.5 19.75C29.5 15.6085 26.1415 12.25 22 12.25C17.8585 12.25 14.5 15.6085 14.5 19.75C14.5 25 22 31.75 22 31.75C22 31.75 29.5 25 29.5 19.75Z"
          fill="white"
        />
      </svg>
    </span>
  );
}
