import { AppThunk, createAction, createAsyncAction } from 'typesafe-actions';
import {
  StationInfoResponse,
  SingleStationInfo,
  StationSearchParams,
  SearchInfo,
} from 'Station-Info-Types';
import { getStationInfo } from './api';
import { fetchError } from 'ReservationStep1-Types';
import { CancelRequest } from 'utils/fetch';

export const fetchStationInfoAsync = createAsyncAction(
  'FETCH_STATION_INFO_REQUEST',
  'FETCH_STATION_INFO_SUCCESS',
  'FETCH_STATION_INFO_FAILURE',
)<undefined, StationInfoResponse, any>();

export const fetchStationInfo = (req): AppThunk => async dispatch => {
  dispatch(fetchStationInfoAsync.request());
  try {
    CancelRequest();
    const response = await getStationInfo(req);
    dispatch(fetchStationInfoAsync.success(response));
  } catch (error) {
    dispatch(fetchStationInfoAsync.failure(error as fetchError));
  }
};

export const updateSingleStationInfo = createAction('UPDATE_STATION_INFO')<SingleStationInfo>();
export const setDialogOpenStatus = createAction('SET_DIALOG_OPEN')<boolean>();
export const updateStationSearchParams = createAction('UPDATE_STATION_SEARCH_PARAMS')<
  StationSearchParams
>();
export const updateSearchInfo = createAction('UPDATE_SEARCH_INFO')<SearchInfo>();
