type Props = {
  className?: string;
  color?: string;
};

export default function Home(props: Props) {
  const { className, color = '#333333' } = props;
  return (
    <span className={className}>
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 7.5L7.5 0.5L14.5 7.5H12.5V12.5C12.5 13.052 12.052 13.5 11.5 13.5H9.75V10.4565C9.75 9.6535 9.0965 9 8.2935 9H6.7065C5.9035 9 5.25 9.6535 5.25 10.4565V13.5H3.5C2.948 13.5 2.5 13.052 2.5 12.5V7.5H0.5ZM6.70645 10H8.29345C8.54545 10 8.74995 10.2045 8.74995 10.4565V13.5H6.24995V10.4565C6.24995 10.2045 6.45445 10 6.70645 10Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
