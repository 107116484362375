type Props = {
  className?: string;
  color?: string;
};

export default function Close(props: Props) {
  const { color = '#000000', className } = props;
  return (
    <span className={className}>
      <svg
        width="15"
        height="11"
        viewBox="0 0 15 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5 0.707L13.793 0L5 8.7925L1.207 5L0.5 5.707L5 10.207L14.5 0.707Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
