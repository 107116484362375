import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  CalculatePriceResponse,
  CardListResponse,
  CheckCreditCardInfoResponse,
  CheckPartnerTermsResponse,
  CheckPointInfoResponse,
  ReservationStep2RetData,
  ReservationStepError,
  PointResponse,
  CouponsListResponse,
  CalculatePriceCoupons,
} from 'ReservationStep2-Types';
import {
  fetchCalculatePriceAsync,
  fetchCardListAsync,
  fetchCheckCreditCardInfoAsync,
  fetchCheckPartnerTermsAsync,
  fetchCheckPointInfoAsync,
  fetchPointAsync,
  fetchReservationStep2Async,
  fetchCouponsListAsync,
  reservationCoupon,
} from './actions';
import {
  reStep2InitReducer,
  checkPartnerTermsInitReducer,
  calculatePriceInitReducer,
  reCardListInitReducer,
  rePointInitReducer,
  reCouponsListInitReducer,
} from 'constants/reservationStep';
export const isFetchingCalculatePrice = createReducer<boolean>(false)
  .handleAction([fetchCalculatePriceAsync.request], () => true)
  .handleAction([fetchCalculatePriceAsync.success, fetchCalculatePriceAsync.failure], () => false);

export const isFetchingCalculatePriceSuccess = createReducer<CalculatePriceResponse>(
  calculatePriceInitReducer,
).handleAction(fetchCalculatePriceAsync.success, (_state, action) => action.payload);

export const isFetchingCalculatePriceFailure = createReducer<ReservationStepError>(
  {} as ReservationStepError,
).handleAction(fetchCalculatePriceAsync.failure, (_state, action) => action.payload);

export const isFetchingReservationStep2 = createReducer<boolean>(false)
  .handleAction([fetchReservationStep2Async.request], () => true)
  .handleAction(
    [fetchReservationStep2Async.success, fetchReservationStep2Async.failure],
    () => false,
  );
export const isFetchingReservationStep2Success = createReducer<ReservationStep2RetData>(
  reStep2InitReducer,
).handleAction(fetchReservationStep2Async.success, (_state, action) => action.payload);

export const isFetchingReservationStep2Failure = createReducer<ReservationStepError>(
  {} as ReservationStepError,
).handleAction(fetchReservationStep2Async.failure, (_state, action) => action.payload);

export const isFetchingCheckPartnerTerms = createReducer<boolean>(false)
  .handleAction([fetchCheckPartnerTermsAsync.request], () => true)
  .handleAction(
    [fetchCheckPartnerTermsAsync.success, fetchCheckPartnerTermsAsync.failure],
    () => false,
  );
export const isFetchingCheckPartnerTermsSuccess = createReducer<CheckPartnerTermsResponse>(
  checkPartnerTermsInitReducer,
).handleAction(fetchCheckPartnerTermsAsync.success, (_state, action) => action.payload);

export const isFetchingCheckPartnerTermsFailure = createReducer<ReservationStepError>(
  {} as ReservationStepError,
).handleAction(fetchCheckPartnerTermsAsync.failure, (_state, action) => action.payload);

export const isFetchingCardList = createReducer<boolean>(false)
  .handleAction([fetchCardListAsync.request], () => true)
  .handleAction([fetchCardListAsync.success, fetchCardListAsync.failure], () => false);
export const isFetchingCardListSuccess = createReducer<CardListResponse>(
  reCardListInitReducer,
).handleAction(fetchCardListAsync.success, (_state, action) => action.payload);

export const isFetchingCardListFailure = createReducer<ReservationStepError>(
  {} as ReservationStepError,
).handleAction(fetchCardListAsync.failure, (_state, action) => action.payload);

export const isFetchingPoint = createReducer<boolean>(false)
  .handleAction([fetchPointAsync.request], () => true)
  .handleAction([fetchPointAsync.success, fetchPointAsync.failure], () => false);
export const isFetchingPointSuccess = createReducer<PointResponse>(rePointInitReducer).handleAction(
  fetchPointAsync.success,
  (_state, action) => action.payload,
);
export const isFetchingPointFailure = createReducer<ReservationStepError>(
  {} as ReservationStepError,
).handleAction(fetchPointAsync.failure, (_state, action) => action.payload);

export const isFetchingCheckPointInfo = createReducer<boolean>(false)
  .handleAction([fetchCheckPointInfoAsync.request], () => true)
  .handleAction([fetchCheckPointInfoAsync.success, fetchCheckPointInfoAsync.failure], () => false);
export const isFetchingCheckPointInfoSuccess = createReducer<CheckPointInfoResponse>(
  reStep2InitReducer,
).handleAction(fetchCheckPointInfoAsync.success, (_state, action) => action.payload);
export const isFetchingCheckPointInfoFailure = createReducer<ReservationStepError>(
  {} as ReservationStepError,
).handleAction(fetchCheckPointInfoAsync.failure, (_state, action) => action.payload);

export const isFetchingCheckCreditCardInfo = createReducer<boolean>(false)
  .handleAction([fetchCheckCreditCardInfoAsync.request], () => true)
  .handleAction(
    [fetchCheckCreditCardInfoAsync.success, fetchCheckCreditCardInfoAsync.failure],
    () => false,
  );
export const isFetchingCheckCreditCardInfoSuccess = createReducer<CheckCreditCardInfoResponse>(
  reStep2InitReducer,
).handleAction(fetchCheckCreditCardInfoAsync.success, (_state, action) => action.payload);
export const isFetchingCheckCreditCardInfoFailure = createReducer<ReservationStepError>(
  {} as ReservationStepError,
).handleAction(fetchCheckCreditCardInfoAsync.failure, (_state, action) => action.payload);

export const isFetchingCouponsList = createReducer<boolean>(false)
  .handleAction([fetchCouponsListAsync.request], () => true)
  .handleAction([fetchCouponsListAsync.success, fetchCouponsListAsync.failure], () => false);
export const isFetchingCouponsListSuccess = createReducer<CouponsListResponse>(
  reCouponsListInitReducer,
).handleAction(fetchCouponsListAsync.success, (_state, action) => action.payload);

export const isFetchingCouponsListFailure = createReducer<ReservationStepError>(
  {} as ReservationStepError,
).handleAction(fetchCouponsListAsync.failure, (_state, action) => action.payload);

export const getReservationCoupon = createReducer<CalculatePriceCoupons>(
  {} as CalculatePriceCoupons,
).handleAction([reservationCoupon], (_, action) => action.payload);

const reservationStep2Reducer = combineReducers({
  isFetchingCalculatePrice,
  isFetchingCalculatePriceSuccess,
  isFetchingCalculatePriceFailure,
  isFetchingReservationStep2,
  isFetchingReservationStep2Success,
  isFetchingReservationStep2Failure,
  isFetchingCheckPartnerTerms,
  isFetchingCheckPartnerTermsSuccess,
  isFetchingCheckPartnerTermsFailure,
  isFetchingCardList,
  isFetchingCardListSuccess,
  isFetchingCardListFailure,
  isFetchingPoint,
  isFetchingPointSuccess,
  isFetchingPointFailure,
  isFetchingCheckPointInfo,
  isFetchingCheckPointInfoSuccess,
  isFetchingCheckPointInfoFailure,
  isFetchingCheckCreditCardInfo,
  isFetchingCheckCreditCardInfoSuccess,
  isFetchingCheckCreditCardInfoFailure,
  isFetchingCouponsList,
  isFetchingCouponsListSuccess,
  isFetchingCouponsListFailure,
  getReservationCoupon,
});

export default reservationStep2Reducer;
export type ReservationStepState = ReturnType<typeof reservationStep2Reducer>;
