import Request, { HttpMethod, RequestWithSignal } from 'utils/fetch';
import {
  CalculatePriceRequest,
  CalculatePriceResponse,
  CheckCreditCardInfoRequest,
  CheckCreditCardInfoResponse,
  CheckPartnerTermsRequest,
  CheckPartnerTermsResponse,
  CheckPointInfoRequest,
  CheckPointInfoResponse,
  ReservationStep2RetData,
  SearchStep2RequestBody,
  CardListResponse,
  PointResponse,
  CouponsListResponse,
  CouponsListRequest,
} from 'ReservationStep2-Types';

export const fetchCalculatePriceData = (
  req: CalculatePriceRequest,
): Promise<CalculatePriceResponse> =>
  RequestWithSignal(`/reservation/partner/price`, {
    method: HttpMethod.post,
    body: req,
  }).then(response => {
    return response;
  });

export const fetchReservationStep2Data = (
  req: SearchStep2RequestBody,
): Promise<ReservationStep2RetData> =>
  Request(`/reservation/step2`, {
    method: HttpMethod.get,
    body: req,
  }).then(response => {
    return response;
  });

export const fetchCheckPartnerTerms = (
  req: CheckPartnerTermsRequest,
): Promise<CheckPartnerTermsResponse> =>
  Request(`/membership/terms/check`, {
    method: HttpMethod.get,
    body: req,
  }).then(response => {
    return response;
  });

export const fetchCardListData = (): Promise<CardListResponse> =>
  Request(`/membership/getRakutenMembershipInfo`, {
    method: HttpMethod.get,
  }).then(response => {
    return response;
  });
export const fetchPointData = (): Promise<PointResponse> =>
  Request(`/member/point`, {
    method: HttpMethod.get,
  }).then(response => {
    return response;
  });
export const fetchCheckPointInfoData = (
  req: CheckPointInfoRequest,
): Promise<CheckPointInfoResponse> =>
  Request(`/reservation/checkPointInfo`, {
    method: HttpMethod.get,
    body: req,
  }).then(response => {
    return response;
  });
export const fetchCheckCreditCardInfoData = (
  req: CheckCreditCardInfoRequest,
): Promise<CheckCreditCardInfoResponse> =>
  Request(`/reservation/checkCreditCardInfo`, {
    method: HttpMethod.get,
    body: req,
  }).then(response => {
    return response;
  });
export const fetchCouponsListData = (req: CouponsListRequest): Promise<CouponsListResponse> =>
  Request(`/reservation/acquiredCouponList`, {
    method: HttpMethod.post,
    body: req,
  }).then(response => {
    return response;
  });
