import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { ErrorResponse, ReservationLockingResponse } from 'ReservationInfo-Types';
import { fetchCancelReservationAsync, fetchLockingAsync } from './actions';

export const isFetchingCancelReservation = createReducer<boolean>(false)
  .handleAction([fetchCancelReservationAsync.request], () => true)
  .handleAction(
    [fetchCancelReservationAsync.success, fetchCancelReservationAsync.failure],
    () => false,
  );

export const fetchingCancelReservationSuccess = createReducer<ErrorResponse>(
  {} as ErrorResponse,
).handleAction(fetchCancelReservationAsync.success, (_state, action) => action.payload);

export const fetchingCancelReservationFailure = createReducer<ErrorResponse>(
  {} as ErrorResponse,
).handleAction(fetchCancelReservationAsync.failure, (_state, action) => action.payload);

export const isFetchingLocking = createReducer<boolean>(false)
  .handleAction([fetchLockingAsync.request], () => true)
  .handleAction([fetchLockingAsync.success, fetchLockingAsync.failure], () => false);

export const fetchingLockingSuccess = createReducer<ReservationLockingResponse>(
  {} as ReservationLockingResponse,
).handleAction(fetchLockingAsync.success, (_state, action) => action.payload);

export const fetchingLockingFailure = createReducer<ErrorResponse>(
  {} as ErrorResponse,
).handleAction(fetchLockingAsync.failure, (_state, action) => action.payload);

const reservationInfoReducer = combineReducers({
  isFetchingCancelReservation,
  fetchingCancelReservationSuccess,
  fetchingCancelReservationFailure,
  isFetchingLocking,
  fetchingLockingSuccess,
  fetchingLockingFailure,
});

export default reservationInfoReducer;
export type ReservationStepState = ReturnType<typeof reservationInfoReducer>;
