type Props = {
  className?: string;
  color?: string;
};
export default function TriangleAlert(props: Props) {
  const { className, color = '#DF0101' } = props;
  return (
    <span className={className}>
      <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.9999 8C5.6894 8 5.4374 7.748 5.4374 7.4375C5.4374 7.127 5.6894 6.875 5.9999 6.875C6.3104 6.875 6.5624 7.127 6.5624 7.4375C6.5624 7.748 6.3104 8 5.9999 8ZM5.6249 6.125H6.3749V2.75H5.6249V6.125ZM11.1824 8.72638L6.46865 0.395C6.36702 0.215 6.18327 0.125 5.9999 0.125C5.81652 0.125 5.63277 0.215 5.53115 0.395L0.817397 8.72638C0.620522 9.074 0.878522 9.5 1.28615 9.5H10.7136C11.1213 9.5 11.3793 9.074 11.1824 8.72638Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
