type Props = {
  className?: string;
  color?: string;
};
export default function RightArrow(props: Props) {
  const { color = 'black' } = props;
  return (
    <span className={props.className}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.207 1.5L4.5 2.207L10.293 8L4.5 13.793L5.207 14.5L11.707 8L5.207 1.5Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
